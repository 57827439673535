<template>
  <div>
    <v-list dense>
      <v-list-item-group color="primary">
        <template v-for="(link, index) in links">
          <v-list-item :key="index" :to="link.path">
            <v-list-item-icon>
              <v-icon v-text="link.icon" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="link.name" />
            </v-list-item-content>
          </v-list-item>
        </template>

        <!-- <v-list-item v-if="isClinic && isOwner" to="/configuracoes/usuarios">
          <v-list-item-icon>
            <app-icon>groups</app-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Usuários</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="!isFree && isOwner" to="/configuracoes/assinatura">
          <v-list-item-icon>
            <app-icon>monetization_on</app-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Assinatura</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <v-list-item @click="handleRefer()">
          <v-list-item-icon>
            <app-icon>redeem</app-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Compartilhe o Psicoplanner</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="openChangePasswordForm()">
          <v-list-item-icon>
            <app-icon> admin_panel_settings</app-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Redefinir senha</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="logoutAllDevices()">
          <v-list-item-icon>
            <app-icon>logout</app-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Sair em todos os dispositivos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="refresh()">
          <v-list-item-icon>
            <app-icon>system_update</app-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Atualizar App</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <ChangePasswordForm ref="ChangePasswordForm" />
    <RecommendationDialog ref="RecommendationDialog" />
  </div>
</template>

<script>
import ChangePasswordForm from "@/components/auth/forms/ChangePasswordForm.vue";
import RecommendationDialog from "@/components/subscription/sections/RecommendationDialog.vue";
export default {
  components: {
    ChangePasswordForm,
    RecommendationDialog,
  },
  data: () => ({
    selectedItem: 1,
    linksOptions: [
      {
        name: "Configurações da Clínica",
        icon: "mdi-domain",
        path: "/configuracoes/clinica",
      },

      {
        name: "Configurações de Grupos",
        icon: "mdi-account-group-outline",
        path: "/configuracoes/grupos",
      },
      {
        name: "Configurações da Agenda",
        icon: "mdi-calendar",
        path: "/configuracoes/agenda",
      },
      {
        name: "Configurações de Salas",
        icon: "mdi-chair-rolling",
        path: "/configuracoes/salas",
        showFor: { clinic: true, owner: true },
      },
      {
        name: "Configurações de Mensagens",
        icon: "mdi-phone-message-outline",
        path: "/configuracoes/configurar-mensagem",
      },
      {
        name: "Modelos de Anamnese",
        icon: "mdi-forum-outline",
        path: "/configuracoes/anamneses",
      },
      {
        name: "Modelos de Documentos",
        icon: "mdi-file-document-outline",
        path: "/configuracoes/documentos",
      },
      {
        name: "Modelos de Prontuário",
        icon: "mdi-clipboard-text-outline",
        path: "/configuracoes/prontuario",
      },
      {
        name: "Armazenamento de Arquivos",
        icon: "mdi-folder-cog-outline",
        path: "/configuracoes/armazenamento",
      },
      {
        name: "Usuários",
        icon: "mdi-account-multiple-outline",
        path: "/configuracoes/usuarios",
        showFor: { clinic: true, owner: true },
      },
      {
        name: "Assinatura",
        icon: "mdi-cash-usd-outline",
        path: "/configuracoes/assinatura",
        showFor: { notFree: true, owner: true, },

      },
      {
        name: "Minha Conta",
        icon: "mdi-account-circle",
        path: "/configuracoes/minha-conta",
        showFor: { all: true },
      },
    ],
  }),
  computed: {
    company() {
      return this.$store.state.auth.company;
    },

    clinic() {
      return this.$store.state.auth.company.type == "clinic";
    },
    owner() {
      return this.$acl.companyUser().is_owner;
    },
    free() {
      return this.company.last_subscription == null;
    },

    links() {
      return this.linksOptions.filter((link) => {
        return this.showLink(link);
      });
    },
  },
  methods: {
    openChangePasswordForm() {
      this.$refs.ChangePasswordForm.open();
    },
    showLink(link) {


      if (link.showFor != undefined) {

        if (link.path == "/configuracoes/assinatura") {
          return !this.$webtonative.isIosApp
        }


        if (link.showFor.clinic) {
          return (
            link.showFor.clinic == this.clinic &&
            link.showFor.owner == this.owner
          );
        }
        if (link.showFor.notFree) {
          return (
            link.showFor.notFree == !this.free &&
            link.showFor.owner == this.owner
          );
        }
        if (link.showFor.all) {
          return link.showFor.all == true;
        }


      }
      if (link.showFor == undefined) {
        return this.owner;
      }


    },
    handleRefer() {
      this.$refs.RecommendationDialog.open();
    },
    refresh() {
      //forcar atualizacao do manifest do pwq
      window.location.reload();
    },
    logoutAllDevices() {
      this.$http
        .$post("auth/logout-all")
        .then((response) => {
          this.$store.dispatch("auth/logout");
          this.$router.push("/login");
        })
        .catch((error) => {
          this.$toast.error(error);
        });
    },
  },
};
</script>

<style></style>
